import './Contract.less';
import * as R from 'ramda';
import React, { useState } from 'react';
import { Select } from 'antd';
import qs from 'querystring';
import S from 'string';
const Option = Select.Option;

import { withRouter } from 'react-router-dom';
import { Output } from '../../components/index';
import ContractListPage from './ContractListPage';
import { getContractStatus } from '../../lib/Status';

const getQueryField = (location) => (fieldName) => {
  const query = qs.parse(S(location.search).chompLeft('?').s);
  return R.propOr(undefined, fieldName, query);
};

const useStatusFilter = (history, location) => {

  const [statusFilterValue, setStatusFilterValue] = useState(getQueryField(location)('search'));

  const onUpdateStatusFilter = (value) => {
    if (!R.isNil(value)) {
      history.replace(`/contract/history?search=${value}`);
    } else {
      history.replace('/contract/history');
    }
    setStatusFilterValue(R.isEmpty(value) ? undefined : value);
  };

  return {
    onUpdateStatusFilter,
    statusFilterValue,
    statusFilterValues: [
      ['confirmed', getContractStatus('confirmed')],
      ['closed', getContractStatus('closed')],
      ['sentForSignature', getContractStatus('sentForSignature')],
      ['sentForSignatureDelayed', getContractStatus('sentForSignatureDelayed')],
      ['unhold', getContractStatus('unhold')],
      ['active', getContractStatus('active')],
      ['sentToManagementSystem', getContractStatus('sentToManagementSystem')],
      ['rejectedByManagementSystem', getContractStatus('rejectedByManagementSystem')],
      ['confirmedInManagementSystem', getContractStatus('confirmedInManagementSystem')],
      ['confirmedInManagementSystemReopened', getContractStatus('confirmedInManagementSystemReopened')],
      ['sendingForSignature', getContractStatus('sendingForSignature')],
      ['done', getContractStatus('done')],
      ['rejected', getContractStatus('rejected')]
    ]
  };
};

const Find = ({
  history,
  location
}) => {

  const {
    onUpdateStatusFilter,
    statusFilterValue,
    statusFilterValues
  } = useStatusFilter(history, location);

  return (
    <Output>
      <div className="contract-history-top">
        <div className="contract-history-top-title">
          <h4>{'Historique des changements'}</h4>
        </div>
        <div className="contract-history-select-status">
          <Select
            value={statusFilterValue}
            onChange={onUpdateStatusFilter}
            style={{ width: '100%' }}
            allowClear
            placeholder="sélectionnez un statut"
          >
            {
              R.map(option => (
                <Option value={option[0]} key={option[0]}>{option[1]}</Option>
              ), statusFilterValues)
            }
          </Select>
        </div>
      </div>
      <ContractListPage filter={{ status: statusFilterValue }}/>
    </Output>
  );
};

export default withRouter(Find);
