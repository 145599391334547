/**
 * @flow
 * @relayHash 1133544691e16508a6ecf34e4b83f979
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GrantsQueryVariables = {||};
export type GrantsQueryResponse = {|
  +yamlGrants: ?{|
    +id: ?string,
    +value: ?string,
  |}
|};
export type GrantsQuery = {|
  variables: GrantsQueryVariables,
  response: GrantsQueryResponse,
|};
*/


/*
query GrantsQuery {
  yamlGrants {
    id
    value
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "YamlGrants",
    "kind": "LinkedField",
    "name": "yamlGrants",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GrantsQuery",
    "selections": (v0/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GrantsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "1133544691e16508a6ecf34e4b83f979",
    "metadata": {},
    "name": "GrantsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '840416ddc2f35d8eccb50c9f5050e7a6';

module.exports = node;
