/**
 * @flow
 * @relayHash a9eb82d88d0fc38e57cceb9b92c38a59
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateAndApplyYamlGrantsMutationVariables = {|
  configName: string,
  value: string,
|};
export type UpdateAndApplyYamlGrantsMutationResponse = {|
  +updateAndApplyYamlGrants: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type UpdateAndApplyYamlGrantsMutation = {|
  variables: UpdateAndApplyYamlGrantsMutationVariables,
  response: UpdateAndApplyYamlGrantsMutationResponse,
|};
*/


/*
mutation UpdateAndApplyYamlGrantsMutation(
  $configName: ID!
  $value: String!
) {
  updateAndApplyYamlGrants(configName: $configName, value: $value) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "configName",
    "type": "ID!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "value",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "configName",
        "variableName": "configName"
      },
      {
        "kind": "Variable",
        "name": "value",
        "variableName": "value"
      }
    ],
    "concreteType": "BasicResponse",
    "kind": "LinkedField",
    "name": "updateAndApplyYamlGrants",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAndApplyYamlGrantsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAndApplyYamlGrantsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "a9eb82d88d0fc38e57cceb9b92c38a59",
    "metadata": {},
    "name": "UpdateAndApplyYamlGrantsMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0d74ccd57089842d7c1b2149b8ae0065';

module.exports = node;
