/**
 * @flow
 * @relayHash f7d900a683ce19d2ff62c1321ed670fc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractGEDStatusEnum = "done" | "rejected" | "%future added value";
export type ContractStatusEnum = "active" | "closed" | "confirmed" | "confirmedInManagementSystem" | "confirmedInManagementSystemReopened" | "done" | "duplicate" | "ready" | "rejected" | "rejectedByManagementSystem" | "sendingForSignature" | "sentForSignature" | "sentForSignatureDelayed" | "sentToManagementSystem" | "unhold" | "%future added value";
export type OfferTypeEnum = "PREVOYANCE" | "SANTE" | "%future added value";
export type PropositionSourceEnum = "SPID" | "SPID_CLIENT" | "%future added value";
export type RelatedContractTypeEnum = "PARTIEL" | "TOTAL" | "%future added value";
export type PaginationInput = {|
  limit?: ?number,
  page?: ?number,
|};
export type ContractFilterInput = {|
  formerContractId?: ?string,
  search?: ?string,
  status?: ?string,
|};
export type ContractListPageQueryVariables = {|
  pagination?: ?PaginationInput,
  filter?: ?ContractFilterInput,
|};
export type ContractListPageQueryResponse = {|
  +contractsWithPagination: ?{|
    +contracts: ?$ReadOnlyArray<?{|
      +id: ?string,
      +source: ?PropositionSourceEnum,
      +propositionNumber: ?number,
      +offerTypes: ?$ReadOnlyArray<?OfferTypeEnum>,
      +siret: ?string,
      +status: ?ContractStatusEnum,
      +GEDstatus: ?ContractGEDStatusEnum,
      +dates: ?{|
        +creation: ?string,
        +lastUpdate: ?string,
      |},
      +siren: ?string,
      +siretInfo: ?{|
        +siret: ?string,
        +siren: ?string,
        +name: ?string,
        +isHeadOffice: ?boolean,
        +nic: ?string,
        +naf: ?string,
        +ape: ?string,
        +legalForm: ?string,
        +address: ?{|
          +city: ?string,
          +postCode: ?string,
          +fields: ?$ReadOnlyArray<?string>,
        |},
        +codeApplication: ?string,
        +identifiantDansSilo: ?string,
      |},
      +extraEstablishments: ?$ReadOnlyArray<?{|
        +codeApplication: ?string,
        +identifiantDansSilo: ?string,
      |}>,
      +ccnId: ?string,
      +companyInformation: ?{|
        +contactLastname: ?string,
        +contactFirstname: ?string,
        +contactEmail: ?string,
        +escAdmin: ?{|
          +exportsDates: ?{|
            +representative: ?string,
            +accessRights: ?string,
          |}
        |},
      |},
      +user: ?{|
        +roles: ?$ReadOnlyArray<?string>,
        +name: ?string,
        +email: ?string,
      |},
      +contractNumbers: ?$ReadOnlyArray<?string>,
      +health: ?{|
        +contractNumbers: ?$ReadOnlyArray<?string>
      |},
      +tags: ?$ReadOnlyArray<?{|
        +id: ?string,
        +value: ?string,
        +dates: ?{|
          +creation: ?string
        |},
      |}>,
      +signature: ?{|
        +id: ?string,
        +type: ?string,
      |},
      +relatedContract: ?{|
        +checked: ?boolean,
        +oldLegalForm: ?string,
        +contractNumbers: ?$ReadOnlyArray<?string>,
        +oldSiren: ?string,
        +relatedContractDate: ?string,
        +startDate: ?string,
        +type: ?RelatedContractTypeEnum,
        +hasKBIS: ?boolean,
      |},
      +amendment: ?{|
        +isAmendment: ?boolean,
        +formerContractId: ?string,
        +formerColleges: ?$ReadOnlyArray<?string>,
        +formerNumberOfContracts: ?number,
      |},
      +statusHistory: ?$ReadOnlyArray<?{|
        +status: ?string,
        +when: ?string,
      |}>,
    |}>,
    +pagination: ?{|
      +total: ?number,
      +page: ?number,
      +pages: ?number,
      +limit: ?number,
    |},
  |}
|};
export type ContractListPageQuery = {|
  variables: ContractListPageQueryVariables,
  response: ContractListPageQueryResponse,
|};
*/


/*
query ContractListPageQuery(
  $pagination: PaginationInput
  $filter: ContractFilterInput
) {
  contractsWithPagination(filter: $filter, pagination: $pagination) {
    contracts {
      id
      source
      propositionNumber
      offerTypes
      siret
      status
      GEDstatus
      dates {
        creation
        lastUpdate
      }
      siren
      siretInfo {
        siret
        siren
        name
        isHeadOffice
        nic
        naf
        ape
        legalForm
        address {
          city
          postCode
          fields
        }
        codeApplication
        identifiantDansSilo
      }
      extraEstablishments {
        codeApplication
        identifiantDansSilo
      }
      ccnId
      companyInformation {
        contactLastname
        contactFirstname
        contactEmail
        escAdmin {
          exportsDates {
            representative
            accessRights
          }
        }
      }
      user {
        roles
        name
        email
      }
      contractNumbers
      health {
        contractNumbers
      }
      tags {
        id
        value
        dates {
          creation
        }
      }
      signature {
        id
        type
      }
      relatedContract {
        checked
        oldLegalForm
        contractNumbers
        oldSiren
        relatedContractDate
        startDate
        type
        hasKBIS
      }
      amendment {
        isAmendment
        formerContractId
        formerColleges
        formerNumberOfContracts
      }
      statusHistory {
        status
        when
      }
    }
    pagination {
      total
      page
      pages
      limit
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pagination",
    "type": "PaginationInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter",
    "type": "ContractFilterInput"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "siret",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creation",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "siren",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "codeApplication",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "identifiantDansSilo",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractNumbers",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      },
      {
        "kind": "Variable",
        "name": "pagination",
        "variableName": "pagination"
      }
    ],
    "concreteType": "ContractsPagination",
    "kind": "LinkedField",
    "name": "contractsWithPagination",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Contract",
        "kind": "LinkedField",
        "name": "contracts",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "source",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "propositionNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "offerTypes",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "GEDstatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Dates",
            "kind": "LinkedField",
            "name": "dates",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastUpdate",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SiretInfo",
            "kind": "LinkedField",
            "name": "siretInfo",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isHeadOffice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nic",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "naf",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ape",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "legalForm",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Address",
                "kind": "LinkedField",
                "name": "address",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "city",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "postCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fields",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SiretInfo",
            "kind": "LinkedField",
            "name": "extraEstablishments",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ccnId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyInformation",
            "kind": "LinkedField",
            "name": "companyInformation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contactLastname",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contactFirstname",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contactEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EscAdmin",
                "kind": "LinkedField",
                "name": "escAdmin",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EscAdminExportsDates",
                    "kind": "LinkedField",
                    "name": "exportsDates",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "representative",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "accessRights",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractUser",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "roles",
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractHealth",
            "kind": "LinkedField",
            "name": "health",
            "plural": false,
            "selections": [
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tag",
            "kind": "LinkedField",
            "name": "tags",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Dates",
                "kind": "LinkedField",
                "name": "dates",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractSignature",
            "kind": "LinkedField",
            "name": "signature",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RelatedContract",
            "kind": "LinkedField",
            "name": "relatedContract",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "checked",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "oldLegalForm",
                "storageKey": null
              },
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "oldSiren",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "relatedContractDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasKBIS",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Amendment",
            "kind": "LinkedField",
            "name": "amendment",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isAmendment",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "formerContractId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "formerColleges",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "formerNumberOfContracts",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractStatusHistory",
            "kind": "LinkedField",
            "name": "statusHistory",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "when",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Pagination",
        "kind": "LinkedField",
        "name": "pagination",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "total",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "page",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pages",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "limit",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractListPageQuery",
    "selections": (v11/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContractListPageQuery",
    "selections": (v11/*: any*/)
  },
  "params": {
    "id": "f7d900a683ce19d2ff62c1321ed670fc",
    "metadata": {},
    "name": "ContractListPageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5fd2c880600e1f4ff54c1755ff8f45f9';

module.exports = node;
