import './Contract.less';

import React, { useState } from 'react';
import qs from 'querystring';
import S from 'string';
import { withRouter } from 'react-router-dom';
import { Output } from '../../components/index';
import { Input } from 'antd';
import ContractListPage from './ContractListPage';

const Find = ({
  location,
  history
}) => {
  const query = qs.parse(S(location.search).chompLeft('?').s);
  const [searchString, setSearchString] = useState(query.search);

  const onChange = (e) => {
    setSearchString(e.target.value);
    history.replace(`/contract/find?search=${e.target.value}`);
  };

  return <Output>
    <h4>{'Recherche d\'un contrat'}</h4>
    <Input
      placeholder="SIREN ou SIRET ou nom du client ou email utilisateur"
      value={searchString}
      onChange={onChange}
      style={{ marginBottom: '8px' }}
    />
    <ContractListPage filter={{ search: searchString }}/>
  </Output>;
};

export default withRouter(Find);
