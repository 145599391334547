import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import { Output } from '../../components/index';
import GrantsEditor from './components/GrantsEditor';

const GrantsQuery = graphql`
query GrantsQuery {
  yamlGrants {
    id
    value
  }
} 
`;

const Grant = () => {

  return (
    <Output>
      <h4>{'Gestion des droits sur les fonctionnalités'}</h4>
      <QueryRenderer
        environment={environment}
        query={GrantsQuery}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.stack}</div>;
          } else if (!props) return null;

          const { yamlGrants } = props;

          return <GrantsEditor configName={yamlGrants.id} value={yamlGrants.value}/>;

        }}
      />
    </Output>
  );
};

export default Grant;
