
import React, { useState } from 'react';

import UpdateAndApplyYamlGrantsMutation from '../../../mutations/UpdateAndApplyYamlGrantsMutation';

import {
  Button,
  Input,
  Spin
} from 'antd';

const useGrantsEditor = (configName, value) => {
  const [editedValue, setEditedValue] = useState(value);
  const [working, setWorking] = useState(false);

  const onUpdateEditedValue = (e) => {
    setEditedValue(e.target.value);
  };

  const onSave = () => {
    setWorking(true);
    UpdateAndApplyYamlGrantsMutation(configName, editedValue, () => {
      setWorking(false);
    });
  };

  return {
    editedValue,
    onUpdateEditedValue,
    onSave,
    working
  };
};

const GrantsEditor = ({ configName, value }) => {

  const {
    editedValue,
    working,
    onUpdateEditedValue,
    onSave
  } = useGrantsEditor(configName, value);

  return (
    <div>
      <Input.TextArea
        value={editedValue}
        autoSize={{
          minRows: 20
        }}
        onChange={onUpdateEditedValue}
      />
      <br/>
      <br/>
      <Button
        onClick={onSave}
        disabled={working}
      >
        {'Enregistrer et Appliquer'}
        { working && <React.Fragment>&nbsp;<Spin size="small"/></React.Fragment>}
      </Button>
    </div>
  );
};

export default GrantsEditor;
