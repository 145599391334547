import * as R from 'ramda';
import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import { Loading } from '../../components/helpers';
import ContractList from './ContractList';
import usePagination from '../../hooks/usePagination';

const ContractListPageQuery = graphql`
  query ContractListPageQuery($pagination: PaginationInput, $filter: ContractFilterInput) {
    contractsWithPagination(filter: $filter, pagination: $pagination) {
      contracts {
        id
        source
        propositionNumber
        offerTypes
        siret
        status
        GEDstatus
        dates {
          creation
          lastUpdate
        }
        siren
        siretInfo {
          siret
          siren
          name
          isHeadOffice
          nic
          naf
          ape
          legalForm
          address {
            city
            postCode
            fields
          }
          codeApplication
          identifiantDansSilo
        }
        extraEstablishments {
          codeApplication
          identifiantDansSilo
        }
        ccnId
        companyInformation {
          contactLastname
          contactFirstname
          contactEmail
          escAdmin {
            exportsDates {
              representative
              accessRights
            }
          }
        }
        user {
          roles
          name
          email
        }
        contractNumbers
        health {
          contractNumbers
        }
        tags {
          id
          value
          dates {
            creation
          }
        }
        signature {
          id
          type
        }
        relatedContract {
          checked
          oldLegalForm
          contractNumbers
          oldSiren
          relatedContractDate
          startDate
          type
          hasKBIS
        }
        amendment {
          isAmendment
          formerContractId
          formerColleges
          formerNumberOfContracts
        }
        statusHistory {
          status
          when
        }
      }
      pagination {
        total
        page
        pages
        limit
      }
    }
  } 
`;

const ContractListPage = ({
  filter
}) => {

  const {
    pagination,
    onUpdatePageSize,
    onUpdatePageNumber
  } = usePagination();

  if (R.has('search', filter) && !R.isNil(filter.search)) {
    if (!R.isEmpty(filter.search) && R.length(filter.search) <= 1) return null;
  }

  const getFilter = () => {
    const f = {};
    if (R.isNil(filter.search) || R.isEmpty(filter.search))
      if (R.isNil(filter.status) || R.isEmpty(filter.status))
        return f;
      else
        return { ...f, status: filter.status };
    else
      return { ...f, search: filter.search };
  };

  return <QueryRenderer
    lookup
    environment={environment}
    query={ContractListPageQuery}
    variables={{
      pagination,
      filter: getFilter()
    }}
    render={({ error, props }) => {
      if (!R.isNil(error)) {
        return (
          <div>
            {'Nous avons rencontré un problème'}
          </div>
        );
      }
      if (props) {
        const contracts = R.pathOr([], ['contractsWithPagination', 'contracts'], props);
        const pagination = R.pathOr({}, ['contractsWithPagination', 'pagination'], props);

        return <ContractList
          contracts={contracts}
          pagination={pagination}
          onUpdatePageNumber={onUpdatePageNumber}
          onUpdatePageSize={onUpdatePageSize}
        />;
      }

      return <Loading/>;
    }}
  />;
};

export default ContractListPage;
