import * as R from 'ramda';
import React from 'react';
import ContractThumb from '../../components/contract/ContractThumb';
import Moment from 'moment';
import { Collapse, Tag } from 'antd';
const Panel = Collapse.Panel;
import { getContractStatus } from '../../lib/Status';
import LocalPagination from '../../components/LocalPagination';

const PanelHeader = ({ node: { propositionNumber, status, siret, dates = {}, amendment } }) => (
  <div style={{ display: 'flex' }}>
    <div style={{ flex: 2 }}>
      <Tag>
        {`proposition : ${propositionNumber}`}
      </Tag>
      <Tag>
        {`SIRET : ${siret}`}
      </Tag>
      <Tag>
        {!R.isNil(dates) && Moment(dates.lastUpdate).format('DD/MM/YYYY HH:mm:ss')}
      </Tag>
      {R.propOr(false, 'isAmendment')(amendment) && <Tag>
        {'AVENANT'}
      </Tag>}
    </div>

    <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
      <Tag color={R.prop(status, {
        'unhold': 'cyan',
        'active': 'gold',
        'closed': 'purple',
        'confirmed': 'blue',
        'sendingForSignature': 'volcano',
        'sentForSignature': 'volcano',
        'sentForSignatureDelayed': 'volcano',
        'sentToManagementSystem': 'lime',
        'rejectedByManagementSystem': 'cyan',
        'confirmedInManagementSystem': 'magenta',
        'confirmedInManagementSystemReopened': 'magenta',
        'duplicate': 'blue'
      })}
      >
        {getContractStatus(status)}
      </Tag>
    </div>
  </div>
);

const Space = () => <div style={{ margin: '8px 0' }}/>;

const ContractList = ({
  contracts,
  pagination,
  onUpdatePageNumber,
  onUpdatePageSize
}) => {

  if (R.isEmpty(contracts)) return (
    <div>
      Pas de réponse
    </div>
  );

  return (
    <React.Fragment>
      <LocalPagination pagination={pagination} onUpdatePageNumber={onUpdatePageNumber} onUpdatePageSize={onUpdatePageSize} type="top"/>
      <Space/>
      <Collapse
        accordion
      >
        {contracts.map((contract) => (
          <Panel header={<PanelHeader node={contract}/>} key={contract.id}>
            <ContractThumb key={contract.id} contract={contract}/>
          </Panel>
        ))}
      </Collapse>
      <Space/>
      <LocalPagination pagination={pagination} onUpdatePageNumber={onUpdatePageNumber} onUpdatePageSize={onUpdatePageSize} type="bot"/>
    </React.Fragment>
  );
};

ContractList.defaultProps = {
  count: 25
};

export default ContractList;
