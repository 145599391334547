import * as R from 'ramda';

import React, { useState, useEffect } from 'react';
import { Output } from '../../components/index';
import { Input } from 'antd';

import qs from 'querystring';
import S from 'string';

import {
  withRouter
} from 'react-router-dom';

import { ElectronicSignatureDetails } from '../../components/electronicSignature';

const useInputFieldFromQueryUrl = (url, queryField, location, history) => {
  const getInputFieldValue = (location) => {
    const query = qs.parse(S(location.search).chompLeft('?').s);
    return query[queryField];
  };
  const [inputField, setInputField] = useState(getInputFieldValue(location));

  useEffect(() => {
    setInputField(getInputFieldValue(location));
  }, [getInputFieldValue, location]);

  const onUpdateInputField = (e) => {
    history.replace(`${url}?${queryField}=${e.target.value}`);
  };

  return {
    inputField,
    onUpdateInputField
  };
};

const Details = ({
  location,
  history
}) => {
  const {
    inputField,
    onUpdateInputField
  } = useInputFieldFromQueryUrl('/universign/details', 'id', location, history);

  return (
    <Output>
      <h4>{'Recherche d\'une signature électronique'}</h4>
      <Input
        placeholder="id de signature électronique"
        value={inputField}
        onChange={onUpdateInputField}
      />
      {
        !R.isNil(inputField) && !R.isEmpty(inputField) && (
          <React.Fragment>
            {inputField.split(',').map((id, index) => <ElectronicSignatureDetails key={index} id={id}/>)}
          </React.Fragment>
        )
      }
    </Output>
  );
};

export default withRouter(Details);
