import * as R from 'ramda';
import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import { Loading } from '../helpers';
import { Card, Row } from 'antd';
import ContractGeneral from './ContractGeneral';
import ContractDetails from './ContractDetails';

const ContractOriginQuery = graphql`
  query ContractOriginQuery ($contractId: ID!) {
    contract (contractId: $contractId) {
      id
      propositionNumber
      siret
      siretInfo {
        name
      }
      companyInformation {
        contactLastname
        contactFirstname
        contactEmail
      }
      user {
        roles
        name
        email
      }
      signature {
        type
        id
      }
      contractNumbers
    }
  }
`;

const formatUnknownAmendmentContract = contract => R.compose(
  R.assoc('contractDescription', R.pathOr({}, ['amendment', 'formerContractDescription'], contract))
)(contract);

const CardOrigin = ({
  formerContract,
  formerContractId,
  isUnknownAmendment = false
}) => {
  const propositionNumber = R.prop('propositionNumber')(formerContract);

  return <Card
    title={isUnknownAmendment ? 'Contrat d\'origine' : <a href={`/contract/find?search=${propositionNumber}`}>Contrat d&#39;origine</a>}
    size="small"
    style={{ marginTop: '5xp' }}
  >
    <Row gutter={8} style={{ padding: '8px', backgroundColor: '#fafafa' }}>
      <ContractGeneral contract={formerContract} isAmendment={false}/>
      <ContractDetails
        contractId={formerContractId}
        formatContract={isUnknownAmendment ? formatUnknownAmendmentContract : undefined}
      />
    </Row>
  </Card>;
};

const ContractOrigin = ({ contract }) => {
  const { amendment } = contract;

  if (!R.propEq('isAmendment', true, amendment)) {
    return null;
  }

  const { formerContractId } = amendment;

  if (R.isNil(formerContractId)) {
    return <CardOrigin
      formerContractId={contract.id}
      formerContract={contract}
      isUnknownAmendment
    />;
  }

  return <QueryRenderer
    environment={environment}
    query={ContractOriginQuery}
    variables={{
      contractId: formerContractId
    }}
    render={({ error, props }) => {
      if (error) return <div>{error}</div>;
      if (!props) return <Loading/>;

      const formerContract = R.path(['contract'])(props);
      if (R.isNil(formerContract)) return null;

      return <CardOrigin
        formerContractId={formerContractId}
        formerContract={formerContract}
      />;
    }}
  />;

};

export default ContractOrigin;
